import { setUserAction } from './actions';

export function setUser(data) {
  return async dispatch => {
    try {
      return dispatch(setUserAction(data));
    } catch (error) {
      console.log(error);
    }
  };
}
