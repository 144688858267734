const actions = {
  SS_TOGGLE_VIEW_MODE: 'SS_TOGGLE_VIEW_MODE',
  SS_RESET_STATE: 'SS_RESET_STATE',
  SS_SET_SEARCH: 'SS_SET_SEARCH',
  SS_SET_ORDER: 'SS_SET_ORDER',
  SS_SET_DIIRECTION: 'SS_SET_DIIRECTION',
  SS_SET_OFFSET: 'SS_SET_OFFSET',
  SS_SET_LIMIT: 'SS_SET_LIMIT',
  SS_SET_TOTAL_COUNT: "SS_SET_TOTAL_COUNT",

  reset: () => {
    return {
      type: actions.SS_RESET_STATE
    };
  },
  setSearch: (data) => {
    return {
      type: actions.SS_SET_SEARCH,
      data
    };
  },
  setOrder: (data) => {
    return {
      type: actions.SS_SET_ORDER,
      data
    };
  },
  setDirection: (data) => {
    return {
      type: actions.SS_SET_DIIRECTION,
      data
    };
  },
  setOffset: (data) => {
    return {
      type: actions.SS_SET_OFFSET,
      data
    };
  },
  setLimit: (data) => {
    return {
      type: actions.SS_SET_LIMIT,
      data
    };
  },
  setTotalCount: (data) => {
    return {
      type: actions.SS_SET_TOTAL_COUNT,
      data
    };
  },
  toggleViewMode: (data) => {
    return {
      type: actions.SS_TOGGLE_VIEW_MODE,
      data
    };
  }
};

export default actions;
