const actions = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DESTROY: 'DESTROY',
  ADD_ELEMENTS: 'ADD_NODE',
  REMOVE_ELEMENTS: 'REMOVE_NODE',
  ADD_NODES: 'ADD_NODES',
  REPLACE_NODE: 'REPLACE_NODE',
  REMOVE_NODE: 'REMOVE_NODE',
  UPDATE_NODE_DATA: 'UPDATE_NODE_DATA',

  createAction: initialState => {
    return {
      type: actions.CREATE,
      data: initialState,
    };
  },

  updateAction: data => {
    return {
      type: actions.UPDATE,
      data,
    };
  },

  destroyAction: () => {
    return {
      type: actions.DESTROY,
    };
  },

  addElementsAction: elements => {
    return {
      type: actions.ADD_ELEMENTS,
      data: { elements },
    };
  },

  removeElementsAction: ids => {
    return {
      type: actions.REMOVE_ELEMENTS,
      data: ids,
    };
  },

  addNodesAction: (parentNodeID, newNodes, configs) => {
    return {
      type: actions.ADD_NODES,
      data: { parentNodeID, newNodes, configs },
    };
  },

  replaceNodeAction: (sourceNode, targetNodeID, configs) => {
    return {
      type: actions.REPLACE_NODE,
      data: { sourceNode, targetNodeID, configs },
    };
  },

  removeNodeAction: id => {
    return {
      type: actions.REMOVE_NODE,
      data: id,
    };
  },

  updateNodeDataAction: (targetNodeID, data, configs) => {
    return {
      type: actions.UPDATE_NODE_DATA,
      data: { targetNodeID, data, configs },
    };
  },
};

export default actions;
