import axios from 'axios';
import { getItem, setItem } from '../../utility/localStorageControl';
import keys from '../../utility/keys';
import strings from '../../utility/strings';
import IdentityService from './identityService';

const authHeader = () => ({
  'X-Auth-Token': getItem(keys.token),
});

const client = axios.create({
  headers: {
    'X-Auth-Token': getItem(keys.token),
    'Content-Type': 'application/json',
  },
});

class DataService {
  static get(path = '', params, optionalHeaders = {}, configs) {
    return client({
      method: 'GET',
      url: path,
      params,
      headers: { ...authHeader(), ...optionalHeaders },
      ...(configs ? configs : {}),
    });
  }

  static post(path = '', data = {}, optionalHeaders = {}, configs) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeaders },
      ...(configs ? configs : {}),
    });
  }

  static patch(path = '', data = {}, optionalHeaders = {}, configs) {
    return client({
      method: 'PATCH',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeaders },
      ...(configs ? configs : {}),
    });
  }

  static put(path = '', data = {}, optionalHeaders = {}, configs) {
    return client({
      method: 'PUT',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeaders },
      ...(configs ? configs : {}),
    });
  }

  static delete(path = '', data = {}, optionalHeaders = {}, configs) {
    return client({
      method: 'DELETE',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeaders },
      ...(configs ? configs : {}),
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(config => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = { ...headers, 'X-Auth-Token': getItem(keys.token) };

  return requestConfig;
});

let isRefreshing = false;
let refreshPromise = null;
client.interceptors.response.use(
  response => {
    const data = response.data;

    if (data.resultCode === 401) {
      IdentityService.logOut();
      data.messages = [strings().jwtTokenExpired];
    }

    return data;
  },
  async error => {
    setItem('axios-error', JSON.stringify(error.response || error));
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    // const { response } = error;
    // if (response) {
    //   if (response.status === 401) {
    //     IdentityService.logOut();
    //     return { succeeded: false, messages: [strings().jwtTokenExpired] };
    //   } else return { succeeded: false, messages: [strings().unknownError] };
    // }
    const originalRequest = error.config;
    if (error.response && error.response.status === 401) {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        if (!isRefreshing) {
          isRefreshing = true;
          refreshPromise = IdentityService.refreshToken().finally(() => {
            isRefreshing = false;
            refreshPromise = null;
          });
        }
        try {
          await refreshPromise;
          originalRequest.headers['X-Auth-Token'] = getItem(keys.token);
          return client(originalRequest);
        } catch (refreshError) {
          // If refresh fails, this will be executed
          return Promise.reject(refreshError); // Make sure to reject the promise to stop the request flow
        }
      }
    }
    return { succeeded: false, messages: [strings().unknownError] };
  },
);
export { DataService };
