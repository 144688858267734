import actions from './actions';
import keys from '../../utility/keys';
import { getItem } from '../../utility/localStorageControl';
const { SET_DATA, TOGGLE_LOADING, SET_TENANT } = actions;

const initState = {
  loading: false,
  tenants: [],
  currentTenant: ''
};

/**
 *
 * @todo impure state mutation/explaination
 */
const TenantReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: data,
      };
    case SET_DATA:
      return {
        ...state,
        tenants: data
      };
    case SET_TENANT:
      return {
        ...state,
        currentTenant: data
      };
    default:
      return state;
  }
};
export default TenantReducer;
