const actions = {
    SHOW_MODAL: 'SHOW_MODAL',
    HIDE_MODAL: 'HIDE_MODAL',

    showModalAction: data => {
        return {
            type: actions.SHOW_MODAL,
            data
        };
    },

    hideModalAction: data => {
        return {
            type: actions.HIDE_MODAL,
            data,
        };
    }
};

export default actions;
