import actions from './actions';
import { setItem } from '../../utility/localStorageControl'
import keys from '../../utility/keys';
const { loading, setData, setTenant } = actions;

const toggleLoading = (data) => {
  return async dispatch => {
    try {
      return dispatch(loading(data));
    } catch (err) {

    }
  };
};

const setNewData = (data) => {
  return async dispatch => {
    try {
      dispatch(setData(data));
    } catch (err) {
    }
  };
};
const setNewTenant = (data) => {
  setItem(keys.tenant, JSON.stringify(data))
  return async dispatch => {
    try {
      dispatch(setTenant(data));
    } catch (err) {
    }
  };
};
export { toggleLoading, setNewData, setNewTenant };
