export function removeNode(tree, targetNodeID) {
  if (tree.node.id == targetNodeID) {
    tree = undefined;

    return true;
  }

  if (tree.children)
    for (const child of tree.children) {
      const isDeleted = removeNode(child, targetNodeID);

      if (isDeleted) return isDeleted;
    }

  return false;
}
