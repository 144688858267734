// wizardReducer.js
import _ from 'lodash';
import { WIZARD_ACTION_TYPES } from './actions';
import { randomNumericString, randomString } from '../../utility/utility';

const initialState = {
  confUser: {
    auth: {
      email_address: '',
      enabled: true, // voice --> false / webrtc --> true
      firstname: '',
      lastname: '',
      password: '',
      purpose: 'user',
      username: '',
    },
    firstname: '',
    lastname: '',
    email: '',
    caller_id: '',
    mobile_phone_number: '',
    username: '',
    // password: '',
    subscription_type: 0,
    enabled: true,
    ring_seconds: 30,
    simultaneous_calls: 5,
    call_transfer_enabled: true,
    dtmf_hangup_enabled: true,
    supervision_enabled: true,
    timezone: 'Asia/Tehran',
    language: 'en_US',
    lines: [
      {
        name: '',
        protocol: 'sip',
        provisioning_extension: '',
        provisioning_code: '',
        position: 1,
        caller_id_name: '',
        caller_id_num: '',
        registrar: 'default',
        endpoint_sip: {
          label: '',
          name: '',
          auth_section_options: [
            ['password', ''],
            ['username', ''],
          ],
          templates: [
            {
              uuid: '',
            },
          ],
        },
        extensions: [
          {
            exten: '',
            context: '',
          },
        ],
      },
    ],
    call_record_outgoing_external_enabled: false,
    call_record_outgoing_internal_enabled: false,
    call_record_incoming_internal_enabled: false,
    call_record_incoming_external_enabled: false,
    online_call_record_enabled: true,
    // call_record_enabled: false,
    voicemail: {
      name: '',
      number: '',
      context: '',
      password: '', // 4 digits --> usr input
      email: '',
      language: 'en_US',
      timezone: 'ir-tehran',
      pager: null,
      max_messages: '50',
      attach_audio: true,
      delete_messages: false,
      ask_password: true,
      enabled: true,
      options: [],
    },
    queues: [],
    // agent: null,
    groups: [],
    incalls: [],
    switchboards: [],
  },
  currentStep: 0,
};

const wizardReducer = (state = initialState, action) => {
  switch (action.type) {
    case WIZARD_ACTION_TYPES.UPDATE_WIZARD_STEP1:
      // Merge only updated data into the existing state, ensuring that initial structures are respected
      let newState = _.merge({}, state, action.payload);

      if (newState.confUser.email) {
        newState.confUser.username = newState.confUser.email;
        newState.confUser.auth = {
          ...newState.confUser.auth,
          email_address: newState.confUser.email,
          firstname: newState.confUser.firstname,
          lastname: newState.confUser.lastname,
          // password: newState.confUser.password,
          username: newState.confUser.username,
        };
        newState.confUser.position = newState.confUser.subscription_type ? 1 : 2;
        // Set username to the email address
      }

      if (newState.confUser.firstname_english && newState.confUser.lastname_english) {
        newState.confUser.caller_id = `\"${newState.confUser.firstname_english} ${newState.confUser.lastname_english}\"`;

        // Update lines based on the requirements specified
        newState.confUser.lines = newState.confUser.lines.map(line => {
          const lineName = line.name || randomString(8, { uppercase: false, numbers: true });
          const randomCode = randomNumericString(6); // Use randomNumericString for a numeric code

          return {
            ...line,
            name: lineName,
            provisioning_extension: randomCode,
            provisioning_code: randomCode,
            caller_id_name: `${newState.confUser.firstname} ${newState.confUser.lastname}`,
            caller_id_num: line.extensions.length > 0 ? line.extensions[0].exten : line.caller_id_num,
            endpoint_sip: {
              ...line.endpoint_sip,
              label: lineName,
              name: lineName,
              auth_section_options: [
                ['password', randomString(8, { uppercase: true, numbers: true })],
                ['username', lineName],
              ],
            },
          };
        });
      }

      return newState;

    case WIZARD_ACTION_TYPES.UPDATE_WIZARD_STEP2:
      return _.merge({}, state, action.payload);

    case WIZARD_ACTION_TYPES.UPDATE_WIZARD_STEP3:
      // Process for step 3 (voicemail)
      let updatedState = _.merge({}, state, action.payload);
      // Ensures voicemail context and number are updated according to the lines
      if (updatedState.confUser.lines.length > 0) {
        updatedState.confUser.voicemail.context = updatedState.confUser.lines[0].context;
        updatedState.confUser.voicemail.number = updatedState.confUser.lines[0].caller_id_num;
        updatedState.confUser.voicemail.name = `${updatedState.confUser.firstname} ${updatedState.confUser.lastname}`;
        updatedState.confUser.voicemail.email = updatedState.confUser.email;
      }
      return updatedState;

    case WIZARD_ACTION_TYPES.SET_CURRENT_STEP:
      return { ...state, currentStep: action.payload };
    default:
      return state;
  }
};

export default wizardReducer;
