import actions from './actions';
const { showModalAction, hideModalAction } = actions;

const showConfirmModal = (data) => {
    return async dispatch => {
        try {
            return dispatch(showModalAction(data));
        } catch (err) {
            console.log(err)
        }
    };
};

const hideConfirmModal = () => {
    return async dispatch => {
        try {
            dispatch(hideModalAction());
        } catch (err) {
            console.log(err)
        }
    };
};

export { showConfirmModal, hideConfirmModal };
