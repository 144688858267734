import actions from './actions';

const initialState = null;

const { SET_DATA, APPEND_DATA, UPDATE_DATA, DELETE_DATA, DESTROY_DATA } = actions;

const chartContentReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case SET_DATA:
      return data;

    case APPEND_DATA:
      return { ...state, ...data };

    case UPDATE_DATA:
      for (const prop in data) state[prop] = data[prop];

      return { ...state };

    case DELETE_DATA:
      const { propName } = data;
      delete state[propName];

      return state;

    case DESTROY_DATA:
      return null;

    default:
      return state;
  }
};

export default chartContentReducer;
