import React from 'react';
import { ModalStyled } from './style';
import { hideConfirmModal } from '../../../../redux/confirmModal/actionCreator';
import { useDispatch, useSelector } from 'react-redux'
const ConfirmModal = () => {
    const dispatch = useDispatch();
    const state = useSelector(s => s.confirmModal);
    //=== Hooks
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const afterClose = async () => {
        if (state.afterClose) {
            if (state.afterClose.constructor && state.afterClose.constructor.name == 'AsyncFunction') await state.afterClose();
            else state.afterClose();
        }
    }
    const onCancel = () => {
        dispatch(hideConfirmModal());
    }
    const handleOk = async () => {
        setConfirmLoading(true);
        if (state.onOk) {
            if (state.onOk.constructor && state.onOk.constructor.name == 'AsyncFunction') await state.onOk();
            else state.onOk();
        }
        setConfirmLoading(false);
        dispatch(hideConfirmModal());
    };
    return (
        <ModalStyled
            title={state.title}
            visible={state.visible}
            afterClose={afterClose}
            width={state.width}
            className={state.className}
            onOk={handleOk}
            onCancel={onCancel}
            closable={state.closable}
            maskClosable={state.closable}
            confirmLoading={confirmLoading}
            centered={state.centered}
        >
            {state.message}
        </ModalStyled>
    );
};

export default ConfirmModal;