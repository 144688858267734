import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from '../../../components/dropdown/dropdown';
import { NavAuth } from '../../../components/utilities/auth-info/auth-info-style';
import config from '../../../config/config';
import keys from '../../../utility/keys';
import { setItem } from '../../../utility/localStorageControl';

export default function LanguageSwitch({ className, ...props }) {
  const [state, setState] = useState({
    flag: config.currentLang.culture,
  });

  const { flag } = state;

  const onFlagChangeHandle = value => {
    setItem(keys.lang, value);
    window.location.reload();
    setState({
      ...state,
      flag: value,
    });
  };

  const country = (
    <NavAuth>
      {config.langs.map((lang, idx) => (
        <Link key={idx} onClick={() => onFlagChangeHandle(lang.culture)} to="#">
          <img src={lang.image} alt="" />
          <span>{lang.name}</span>
        </Link>
      ))}
    </NavAuth>
  );

  return (
    <div className={'nav-author ' + className} {...props}>
      <Dropdown placement="bottomRight" content={country} trigger="click">
        <Link to="#" className="head-example">
          <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
        </Link>
      </Dropdown>
    </div>
  );
}
