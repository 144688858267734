const actions = {
  INIT_DATA: "INIT_DATA",
  SET_ORIGINAL_DATA: 'SET_ORIGINAL_DATA',
  SET_FILTERED_DATA: 'SET_FILTERED_DATA',
  TOGGLE_VIEW_MODE: 'TOGGLE_VIEW_MODE',
  RESET_STATE: 'RESET_STATE',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  initData: (data) => {
    return {
      type: actions.INIT_DATA,
      data
    };
  },
  reset: () => {
    return {
      type: actions.RESET_STATE
    };
  },
  setPageSize: (data) => {
    return {
      type: actions.SET_PAGE_SIZE,
      data
    };
  },
  setPageNumber: (data) => {
    return {
      type: actions.SET_PAGE_NUMBER,
      data
    };
  },
  setOriginalData: (data) => {
    return {
      type: actions.SET_ORIGINAL_DATA,
      data
    };
  },

  setFilteredData: (data) => {
    return {
      type: actions.SET_FILTERED_DATA,
      data
    };
  },

  toggleViewMode: (data) => {
    return {
      type: actions.TOGGLE_VIEW_MODE,
      data
    };
  }
};

export default actions;
