const ACTION_TYPES = {
  SET_USER: 'SET_USER',
};

function setUserAction(data) {
  return {
    type: ACTION_TYPES.SET_USER,
    data,
  };
}

export { ACTION_TYPES, setUserAction };
