// store.js

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducers';
import { getItem, setItem } from '../utility/localStorageControl';

// Conditionally apply Redux DevTools in development only
const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

// Load user state from localStorage
const persistedUserState = {
  user: getItem('userState'),
};

// Create store with middleware
const store = createStore(rootReducer, persistedUserState, composeEnhancers(applyMiddleware(thunk)));

// Subscribe to store changes and save user state to localStorage
store.subscribe(() => {
  const userState = store.getState().user;
  setItem('userState', userState);
});

export default store;
