export function addNodes(tree, parentNodeID, newNodes, incrementID, configs = { replace: false }) {
  (function setIDs(children) {
    for (const nodeObject of children) {
      nodeObject.node.id || (nodeObject.node.id = ++incrementID + '');

      nodeObject.children && setIDs(nodeObject.children);
    }
  })(newNodes);

  if (tree.node.id == parentNodeID) {
    if (!tree.children || configs.replace) tree.children = [...newNodes];
    else tree.children.push(...newNodes);

    return incrementID;
  }

  if (tree.children)
    for (const child of tree.children) {
      const IID = addNodes(child, parentNodeID, newNodes, incrementID, configs);

      if (IID) return IID;
    }

  return null;
}
