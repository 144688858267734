import { DataService } from './dataService';
import Addresses from './../api';
import { getItem } from '../../utility/localStorageControl';
import keys from '../../utility/keys';

export default class TenantService {
  static getCurrentTenant() {
    return getItem(keys.tenant);
  }

  static async getAll(params) {
    return await DataService.get(Addresses().tenant.getAll, params);
  }

  static async getOne(uuid) {
    return await DataService.get(Addresses().tenant.get(uuid));
  }

  static async add(model) {
    return await DataService.post(Addresses().tenant.add, model);
  }

  static async update(model, uuid) {
    return await DataService.put(Addresses().tenant.update(uuid), model);
  }

  static async delete(uuid) {
    return await DataService.delete(Addresses().tenant.delete(uuid));
  }
}
