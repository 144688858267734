import { combineReducers } from 'redux';
import { readMessageReducer } from './message/reducers';
import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { userReducer } from './user/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import tenantReducer from './tenant/reducers';
import crudWithClientPaging from './crudWithClientPaging/reducers';
import crudWithServerPaging from './crudWithServerPaging/reducers';
import drawerReducer from './drawer/reducer';
import modalReducer from './modal/reducer';
import confirmModalReducer from './confirmModal/reducer';
import visualBuilderReducer from './visualBuilder/reducer';
import chartContentReducer from './chartContent/reducers';
import wizardReducer from './wizard/reducer';

const rootReducers = combineReducers({
  headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  user: userReducer,
  auth: authReducer,
  ChangeLayoutMode,
  tenant: tenantReducer,
  crudWithClientPaging,
  crudWithServerPaging,
  drawer: drawerReducer,
  modal: modalReducer,
  confirmModal: confirmModalReducer,
  visualBuilder: visualBuilderReducer,
  chartContent: chartContentReducer,
  wizard: wizardReducer,

});

export default rootReducers;
