const actions = {
    SHOW_CONFIRM_MODAL: 'SHOW_CONFIRM_MODAL',
    HIDE_CONFIRM_MODAL: 'HIDE_CONFIRM_MODAL',

    showModalAction: data => {
        return {
            type: actions.SHOW_CONFIRM_MODAL,
            data
        };
    },

    hideModalAction: data => {
        return {
            type: actions.HIDE_CONFIRM_MODAL,
            data,
        };
    }
};

export default actions;
