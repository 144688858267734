export function updateNodeData(tree, targetNodeID, data, configs = {}) {
  if (tree.node.id == targetNodeID) {
    if (!tree.node.data) tree.node.data = {};

    if (typeof data == 'function') tree.node.data.preservedData = data(tree.node.data.preservedData);
    else tree.node.data.preservedData = data;

    return true;
  }

  if (tree.children)
    for (const child of tree.children) {
      const updated = updateNodeData(child, targetNodeID, data, configs);

      if (updated) return updated;
    }

  return false;
}
