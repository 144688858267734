import React from 'react';
import { ModalStyled } from './style';
import { hideModal } from '../../../../redux/modal/actionCreator';
import { useDispatch, useSelector } from 'react-redux'
const Modal = () => {
    const dispatch = useDispatch();
    const state = useSelector(s => s.modal);
    const afterClose = async () => {
        if (state.afterClose) {
            if (state.afterClose.constructor && state.afterClose.constructor.name == 'AsyncFunction') await state.afterClose();
            else state.afterClose();
        }
    }
    const onCancel = () => {
        dispatch(hideModal());
    }
    return (
        <ModalStyled
            title={state.title}
            visible={state.visible}
            afterClose={afterClose}
            width={state.width}
            className={state.className}
            onCancel={onCancel}
            footer={state.footer}
            closable={state.closable}
            maskClosable={state.closable}
            centered={state.centered}
        >
            {state.children && <state.children {...state.childrenProps} />}
        </ModalStyled>
    );
};

export default Modal;