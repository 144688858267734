import React, { useCallback } from 'react';
import { Button, Form, Input, Select } from 'antd';
import strings from '../../../utility/strings';
import IdentityService from '../../../config/dataService/identityService';
import { useDispatch } from 'react-redux';
import { login, setEmailVerification } from '../../../redux/authentication/actionCreator';
import { Link, useHistory } from 'react-router-dom';
import { failedNotif } from '../../../utility/utility';
import FeatherIcon from 'feather-icons-react';
import pages from '../../../config/pages';
import UserService from '../../../config/dataService/userService';

export default function SignUp() {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = useCallback(async values => {
    const userSubmitResult = await UserService.register(values);

    if (!userSubmitResult.id) return failedNotif();

    const loginResult = await IdentityService.login(values.email, values.password);

    if (loginResult.access_token) {
      dispatch(login());

      const emailVerification = loginResult.email_verified_at;
      dispatch(setEmailVerification(emailVerification));

      if (emailVerification) history.push(`${pages.dashboard}`);
      else UserService.sendEmailVerificationLink();
    } else failedNotif();
  }, []);

  return (
    <>
      <h3 className="main-title">{strings().identity.welcome}</h3>
      <Form onFinish={handleSubmit}>
        <Form.Item name="name" rules={[{ message: strings().validation.required, required: true }]}>
          <Input placeholder={strings().name} prefix={<FeatherIcon icon="info" size={20} />} />
        </Form.Item>
        <Form.Item name="family" rules={[{ message: strings().validation.required, required: true }]}>
          <Input placeholder={strings().lastname} prefix={<FeatherIcon icon="info" size={20} />} />
        </Form.Item>
        <Form.Item name="gender" rules={[{ message: strings().validation.required, required: true }]}>
          <Select placeholder={strings().gender}>
            {Object.entries(strings().genders).map(entry => (
              <Select.Option key={entry[0]} value={entry[0]}>
                {entry[1]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="mobile" rules={[{ message: strings().validation.required, required: true }]}>
          <Input placeholder={strings().mobile} prefix={<FeatherIcon icon="smartphone" size={20} />} />
        </Form.Item>
        <Form.Item name="email" rules={[{ message: strings().validation.required, required: true }]}>
          <Input placeholder={strings().email} prefix={<FeatherIcon icon="user" size={20} />} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ message: strings().validation.required, required: true }]}
          className="pass-input"
        >
          <Input.Password placeholder={strings().identity.password} prefix={<FeatherIcon icon="lock" size={20} />} />
        </Form.Item>
        <Button htmlType="submit" size="large">
          {strings().identity.signUp}
        </Button>
      </Form>
      <div className="signup-link">
        <p>
          {strings().identity.haveAccount}
          <Link to={'/'}>
            <span>{strings().identity.signInNow}</span>
          </Link>
        </p>
      </div>
    </>
  );
}
