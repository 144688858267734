import actions from './actions';
const { showModalAction, hideModalAction } = actions;

const showModal = (data) => {
    return async dispatch => {
        try {
            return dispatch(showModalAction(data));
        } catch (err) {
            console.log(err)
        }
    };
};

const hideModal = () => {
    return async dispatch => {
        try {
            dispatch(hideModalAction());
        } catch (err) {
            console.log(err)
        }
    };
};

export { showModal, hideModal };
