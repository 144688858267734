import React from 'react';
import { Card } from 'antd';
import loginIMG from '../../../static/img/login-img.svg';
import enLogoImage from '../../../static/img/workano-dark-en.svg';
import faLogoImage from '../../../static/img/workano-dark-fa.svg';
import strings from '../../../utility/strings';
import config from '../../../config/config';
import LanguageSwitch from '../../Admin/comps/LanguageSwitch';

export default function AuthContainer({ children }) {
  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        style={{
          width: '45vw',
        }}
        bodyStyle={{ display: 'flex', minHeight: '100%', padding: 0 }}
        className="login-card"
      >
        <div style={{ backgroundColor: '#081A51' }}>
          <img src={loginIMG} />
          <h1>{strings().adminPanel}</h1>
        </div>
        <div>
          <LanguageSwitch className="lang-switch" />
          <img src={config.currentLang.culture === 'fa-IR' ? faLogoImage : enLogoImage} className="logo" />
          {children}
          <div>
            <small>Copyright - Workano Inc.</small>
          </div>
        </div>
      </Card>
    </div>
  );
}
