import actions from "./actions";
import keys from "../../utility/keys";
import { getItem, setItem } from "../../utility/localStorageControl";

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,
  VERIFIED_EMAIL,
} = actions;

const initState = {
  login: getItem(keys.token) ? true : false, //Cookies.get('logedIn'),
  verifiedEmail: getItem(keys.verifiedEmail),
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };

    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };

    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case VERIFIED_EMAIL:
      setItem(keys.verifiedEmail, !!data);

      return {
        ...state,
        verifiedEmail: !!data,
      };

    default:
      return state;
  }
};

export default AuthReducer;
