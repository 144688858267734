import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
// import Message from './message';
import Notification from './notification';
// import Settings from './settings';
// import Support from './support';
import { Popover } from '../../popup/popup';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import IdentityService from '../../../config/dataService/identityService';
import LanguageSwitch from '../../../routes/Admin/comps/LanguageSwitch';
import strings from '../../../utility/strings';
import { useRouteMatch } from 'react-router-dom';
import pages from '../../../config/pages';

const AuthInfo = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.user);
  const { path } = useRouteMatch();

  const SignOut = async e => {
    e.preventDefault();
    await IdentityService.logOut();
    dispatch(logOut());
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        {/* <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h5">{userInfo ? userInfo.firstname + userInfo.lastname : 'Unknown'}</Heading>
          </figcaption>
        </figure> */}
        <ul className="user-dropdwon__links">
          <li>
            <Link to={`${path}/${pages.profile}`}>
              <FeatherIcon icon="user" /> {strings().header.profile}
            </Link>
          </li>
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="settings" /> Settings
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="dollar-sign" /> Billing
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="users" /> Activity
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li> */}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> {strings().header.signout}
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      {/* <Message /> */}
      {/* <Notification /> */}

      {/* <Settings /> */}
      {/* <Support /> */}

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example" style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              icon={<FeatherIcon icon="user" style={{ verticalAlign: 'middle' }} size={30} />}
              size="large"
              style={{ backgroundColor: '#F5F7FB', color: 'black' }}
            />
            <span
              style={{
                fontWeight: 'bold',
                fontSize: '1rem',
                color: 'black',
                margin: '0 12px',
                maxWidth: 120,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {userInfo ? userInfo.firstname + userInfo.lastname : 'Unknown'}
            </span>
            <FeatherIcon icon="chevron-down" style={{ verticalAlign: 'middle' }} /*  size={30} */ />
          </Link>
        </Popover>
      </div>

      <LanguageSwitch />
    </InfoWraper>
  );
};

export default AuthInfo;
