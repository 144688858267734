import React, { useCallback } from 'react';
import { Button, Form, Input } from 'antd';
import strings from '../../../utility/strings';
import { failedNotif } from '../../../utility/utility';
import FeatherIcon from 'feather-icons-react';
import UserService from '../../../config/dataService/userService';

export default function ForgotPassword() {
  const handleSubmit = useCallback(async values => {
    const resetPassLinkResult = await UserService.resetPassword(values);

    if (resetPassLinkResult) failedNotif();
  }, []);

  return (
    <>
      <h3 className="main-title">{strings().identity.welcome}</h3>
      <Form onFinish={handleSubmit}>
        <Form.Item name="email" rules={[{ message: strings().validation.required, required: true }]}>
          <Input placeholder={strings().email} prefix={<FeatherIcon icon="user" size={20} />} />
        </Form.Item>
        <Button htmlType="submit" size="large">
          {strings().button.sendEmail}
        </Button>
      </Form>
      <div className="signup-link"></div>
    </>
  );
}
