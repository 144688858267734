import actions from './actions';
import { paginate } from '../../utility/utility';
const {
  INIT_DATA,
  RESET_STATE,
  SET_PAGE_SIZE,
  SET_PAGE_NUMBER,
  SET_ORIGINAL_DATA,
  SET_FILTERED_DATA,
  TOGGLE_VIEW_MODE,
} = actions;

const initialState = {
  pageSize: 10,
  pageNumber: 1,
  originalData: [],
  filteredData: [],
  pagedData: [],
  viewMode: 'list',
  loading: false,
  error: null,
};

const crudWithClientPagingReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case INIT_DATA:
      return {
        ...state,
        originalData: [...data],
        filteredData: [...data],
        pagedData: paginate([...data], state.pageSize, state.pageNumber),
      };

    case RESET_STATE:
      return {
        ...state,
        pageSize: 10,
        pageNumber: 1,
        originalData: [],
        filteredData: [],
        pagedData: [],
        viewMode: 'list',
        loading: false,
        error: null,
      };

    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: data,
        pagedData: paginate([...state.filteredData], data, state.pageNumber),
      };

    case SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: data,
        pagedData: paginate([...state.filteredData], state.pageSize, data),
      };

    case SET_ORIGINAL_DATA:
      return {
        ...state,
        originalData: [...data],
      };

    case SET_FILTERED_DATA:
      return {
        ...state,
        filteredData: [...data],
        pagedData: paginate([...data], state.pageSize, state.pageNumber),
      };

    case TOGGLE_VIEW_MODE:
      return {
        ...state,
        viewMode: data,
      };

    default:
      return state;
  }
};

export default crudWithClientPagingReducer;
