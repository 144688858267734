const keys = {
  lang: 'lang',
  token: 'token',
  refreshToken: 'refreshToken',
  tenant: 'tenant',
  userInfo: 'userInfo',
  host: 'host',
  user: 'userState',
  axios: 'axios-error',
};

export default keys;
