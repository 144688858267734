import strings from '../../utility/strings';
import actions from './actions';
const { SHOW_CONFIRM_MODAL, HIDE_CONFIRM_MODAL } = actions;

const initState = {
    visible: false,
    onOk: null,
    title: strings().confirmDelete,
    message: strings().areYouSureForDelete,
    centered: true,
    closable: true,
    afterClose: null,
    width: 400,
    footer: true
};

const ModalReducer = (state = initState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SHOW_CONFIRM_MODAL:
            return {
                ...state,
                visible: true,
                title: data.title ?? initState.title,
                message: data.message ?? initState.message,
                onOk: data.onOk ?? initState.onOk,
                centered: data.centered ?? initState.centered,
                closable: data.closable ?? initState.closable,
                afterClose: data.afterClose ?? initState.afterClose,
                width: data.width ?? initState.width,
                footer: data.footer ?? initState.footer,
            };
        case HIDE_CONFIRM_MODAL:
            return {
                ...state,
                ...initState
            };
        default:
            return state;
    }
};
export default ModalReducer;
