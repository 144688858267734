import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import store from './redux/store';
import Admin from './routes/Admin';
import Auth from './routes/Auth';
import './static/css/style.scss';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import pages from './config/pages';

const { theme } = config;

const ProviderConfig = () => {
  const { rtl, isLoggedIn, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: config.currentLang.culture, dir: rtl ? 'rtl' : 'ltr' }} />
      <ConfigProvider direction={rtl ? 'rtl' : 'ltr'} locale={config.currentLang.antLang}>
        <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
          <Router basename={process.env.PUBLIC_URL}>
            {isLoggedIn && path === `${process.env.PUBLIC_URL}/` && <Redirect to={pages.dashboard} />}
            {!isLoggedIn ? <Route path="/" component={Auth} /> : <ProtectedRoute component={Admin} />}
          </Router>
        </ThemeProvider>
      </ConfigProvider>
    </>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
