import actions from './actions';
const { UPDATE_DRAWER, SHOW_DRAWER, HIDE_DRAWER, RESET_DRAWER } = actions;

const initState = {
  visible: false,
  title: '',
  placement: 'right',
  closable: true,
  onClose: null,
  width: 600,
  children: null,
  childrenProps: null,
  drawerData: null,
};

const DrawerReducer = (state = initState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case UPDATE_DRAWER:
      const { drawerData, ...otherStates } = state;

      return {
        ...otherStates,
        drawerData: data,
      };

    case SHOW_DRAWER:
      return {
        visible: true,
        title: data.title,
        children: data.children,
        closable: data.closable ?? initState.closable,
        onClose: data.onClose || initState.onClose,
        placement: data.placement || initState.placement,
        width: data.width ?? initState.width,
        childrenProps: data.childrenProps || null,
        drawerData: data.drawerData || state.drawerData || null,
      };

    case HIDE_DRAWER:
      state.visible = false;
      return {
        ...state,
      };

    case RESET_DRAWER:
      return {
        ...initState,
      };

    default:
      return state;
  }
};
export default DrawerReducer;
