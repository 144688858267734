import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DrawerStyle } from './style';
import { resetDrawer } from '../../../../redux/drawer/actionCreator';

const Drawer = () => {
  const dispatch = useDispatch();
  const drawerState = useSelector(s => s.drawer);
  const handleClose = async () => {
    dispatch(resetDrawer());
    if (drawerState.onClose) {
      if (drawerState.onClose.constructor && drawerState.onClose.constructor.name == 'AsyncFunction') {
        await drawerState.onClose();
      }
      else drawerState.onClose();
    }
  }
  const onClose = async () => {
    handleClose();
  }
  const onVisibilityChange = (v) => {
    if (!v) handleClose();
  }
  return <DrawerStyle
    title={drawerState.title}
    placement={drawerState.placement}
    closable={drawerState.closable}
    onClose={onClose}
    visible={drawerState.visible}
    style={{ position: 'fixed' }}
    width={window.innerWidth < 575 ? "100%" : (drawerState.width ?? 400)}
    className="global-drawer"
    afterVisibleChange={onVisibilityChange}
    destroyOnClose={true}
  >
    {drawerState.children && <drawerState.children {...drawerState.childrenProps} />}
  </DrawerStyle>
}
export default Drawer