const actions = {
  UPDATE_DRAWER: 'UPDATE_DRAWER',
  SHOW_DRAWER: 'SHOW_DRAWER',
  HIDE_DRAWER: 'HIDE_DRAWER',
  RESET_DRAWER: 'RESET_DRAWER',

  updateDrawerAction: data => {
    return {
      type: actions.UPDATE_DRAWER,
      data,
    };
  },

  showDrawerAction: data => {
    return {
      type: actions.SHOW_DRAWER,
      data,
    };
  },

  hideDrawerAction: () => {
    return {
      type: actions.HIDE_DRAWER,
    };
  },

  resetDrawerAction: () => {
    return {
      type: actions.HIDE_DRAWER,
    };
  },
};

export default actions;
