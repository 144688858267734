import actions from './actions';
import { addNodes, replaceNode, removeNode, updateNodeData } from '../../utility/trees';

const {
  CREATE,
  UPDATE,
  DESTROY,
  ADD_ELEMENTS,
  REMOVE_ELEMENTS,
  ADD_NODES,
  REPLACE_NODE,
  REMOVE_NODE,
  UPDATE_NODE_DATA,
} = actions;

const initState = null;

function visualBuilderReducer(state = initState, action) {
  const { type, data } = action;

  switch (type) {
    case CREATE:
      return { ...data };

    case UPDATE:
      if (data && state) {
        const { flow, ...restData } = data;
        for (const itemKey in restData) state[itemKey] = data[itemKey];
      }

      return { ...state };

    case DESTROY:
      return null;

    case ADD_ELEMENTS:
      data.elements && Array.isArray(data.elements)
        ? (state.flow = [...state.flow, ...data.elements])
        : (state.flow = [...state.flow, data.elements]);

      return { ...state };

    case REMOVE_ELEMENTS:
      if (state?.flow) state.flow = state.flow.filter(item => !data.includes(item.id));

      return { ...state };

    case ADD_NODES: {
      const { parentNodeID, newNodes, configs } = data;

      state.totalNodeCreation = addNodes(state.flow, parentNodeID, newNodes, state.totalNodeCreation, configs);

      return { ...state };
    }

    case REPLACE_NODE: {
      const { sourceNode, targetNodeID, configs } = data;

      const replaceResult = replaceNode(state.flow, sourceNode, targetNodeID, state.totalNodeCreation, configs);

      if (typeof replaceResult == 'number') state.totalNodeCreation = replaceResult;

      return { ...state };
    }

    case REMOVE_NODE: {
      removeNode(state.flow, data);

      return { ...state };
    }

    case UPDATE_NODE_DATA: {
      const { targetNodeID, data: preserveData, configs } = data;

      updateNodeData(state.flow, targetNodeID, preserveData, configs);

      return { ...state };
    }

    default:
      return state;
  }
}

export default visualBuilderReducer;
