import actions from "./actions";
import keys from "../../utility/keys";
import { removeItem } from "../../utility/localStorageControl";

const {
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  verifiedEmail,
} = actions;

const login = () => {
  return async (dispatch) => {
    try {
      return dispatch(loginSuccess(true));
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      //Cookies.remove('logedIn');
      removeItem(keys.token);
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const setEmailVerification = (data) => {
  return async (dispatch) => {
    try {
      dispatch(verifiedEmail(data));
    } catch (err) {
      console.log(err);
    }
  };
};

export { login, logOut, setEmailVerification };
