const actions = {
  SET_DATA: 'SET_CHART_DATA',
  APPEND_DATA: 'APPEND_CHART_DATA',
  UPDATE_DATA: 'UPDATE_CHART_DATA',
  DELETE_DATA: 'DELETE_CHART_DATA',
  DESTROY_DATA: 'DESTROY_CHART_DATA',

  setData: data => ({ type: actions.SET_DATA, data }),
  appendData: data => ({ type: actions.APPEND_DATA, data }),
  updateData: data => ({ type: actions.UPDATE_DATA, data }),
  deleteData: propName => ({ type: actions.DELETE_DATA, data: { propName } }),
  destroyData: () => ({ type: actions.DESTROY_DATA }),
};

export default actions;
