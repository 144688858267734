import { ACTION_TYPES } from './actions';

const { SET_USER } = ACTION_TYPES;

const userReducer = (state = null, action) => {
  const { type, data } = action;

  switch (type) {
    case SET_USER: {
      return { ...data };
    }
    default:
      return state;
  }
};

export { userReducer };
