import React, { useCallback, useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import strings from '../../../utility/strings';
import { failedNotif } from '../../../utility/utility';
import FeatherIcon from 'feather-icons-react';
import UserService from '../../../config/dataService/userService';
import QueryString from 'qs';
import { useHistory } from 'react-router-dom';

const { useForm } = Form;

export default function ResetPassword({ location }) {
  const [form] = useForm();
  const history = useHistory();

  const handleSubmit = useCallback(async values => {
    const resetPassResult = await UserService.resetPassword(values);

    if (resetPassResult) failedNotif();

    history.push('/');
  }, []);

  useEffect(() => {
    const { token, email } = QueryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    form.setFieldsValue({ token, email });
  }, [form]);

  return (
    <>
      <h3 className="main-title">{strings().identity.welcome}</h3>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item name="token" rules={[{ required: true }]} hidden>
          <Input />
        </Form.Item>
        <Form.Item name="email" rules={[{ required: true }]} hidden>
          <Input />
        </Form.Item>
        <Form.Item name="password" rules={[{ message: strings().validation.required, required: true }]} hasFeedback>
          <Input.Password placeholder={strings().identity.password} prefix={<FeatherIcon icon="lock" size={20} />} />
        </Form.Item>
        <Form.Item
          name="password_confirmation"
          rules={[
            { message: strings().validation.required, required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(strings().validation.passwordsNotMatch));
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder={strings().identity.passwordConfirmation}
            prefix={<FeatherIcon icon="lock" size={20} />}
          />
        </Form.Item>
        <Button htmlType="submit" size="large">
          {strings().identity.changePassword}
        </Button>
      </Form>
      <div className="signup-link"></div>
    </>
  );
}
