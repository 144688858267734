import { theme, darkTheme } from './theme/themeVariables';
import keys from '../utility/keys';
import { getItem } from '../utility/localStorageControl';
import faIRFlag from '../static/img/flag/fa-IR.png';
import enUSFlang from '../static/img/flag/en-US.png';
import ant_fa_IR from 'antd/lib/locale/fa_IR';
import ant_en_US from 'antd/lib/locale/en_US';

const langs = [
  {
    name: 'فارسی',
    culture: 'fa-IR',
    rtl: true,
    image: faIRFlag,
    antLang: ant_fa_IR,
  },
  {
    name: 'English',
    culture: 'en-US',
    rtl: false,
    image: enUSFlang,
    antLang: ant_en_US,
  },
];

const config = {
  darkMode: false,
  topMenu: false,
  rtl: getItem(keys.lang) ? langs.find(x => x.culture === getItem(keys.lang)).rtl : true,
  theme,
  darkTheme,
  langs: langs,
  currentLang: getItem(keys.lang) ? langs.find(x => x.culture === getItem(keys.lang)) : langs[0],
  // apiBaseUrl: () => `https://${getItem(keys.host)}/api/`,

  apiBaseUrl: () => process.env.REACT_APP_BASE_API_URL,
};

export default config;
