const actions = {
  TOGGLE_LOADING: 'TOGGLE_LOADING',
  SET_DATA: 'SET_DATA',
  SET_TENANT: "SET_TENANT",
  loading: (data) => {
    return {
      type: actions.TOGGLE_LOADING,
      data
    };
  },
  setData: data => {
    return {
      type: actions.SET_DATA,
      data
    };
  },
  setTenant: data => {
    return {
      type: actions.SET_TENANT,
      data
    };
  },
};

export default actions;
