import actions from './actions';
const { SHOW_MODAL, HIDE_MODAL } = actions;

const initState = {
    visible: false,
    title: "",
    centered: true,
    closable: true,
    afterClose: null,
    width: 400,
    children: null,
    childrenProps: null,
    footer: null
};

const ModalReducer = (state = initState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SHOW_MODAL:
            return {
                ...state,
                visible: true,
                title: data.title,
                children: data.children,
                centered: data.centered ?? initState.centered,
                closable: data.closable ?? initState.closable,
                afterClose: data.afterClose ?? initState.afterClose,
                width: data.width ?? initState.width
            };
        case HIDE_MODAL:
            return {
                ...state,
                ...initState
            };
        default:
            return state;
    }
};
export default ModalReducer;
