// wizardActionTypes.js

// export const WIZARD_ACTION_TYPES = {
//   UPDATE_WIZARD_DATA: 'UPDATE_WIZARD_DATA',
// };

// export function updateWizardData(data) {
//   return {
//     type: WIZARD_ACTION_TYPES.UPDATE_WIZARD_DATA,
//     payload: data,
//   };
// }

export const WIZARD_ACTION_TYPES = {
  UPDATE_WIZARD_STEP1: 'UPDATE_WIZARD_STEP1',
  UPDATE_WIZARD_STEP2: 'UPDATE_WIZARD_STEP2',
  UPDATE_WIZARD_STEP3: 'UPDATE_WIZARD_STEP3',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
};

export function updateWizardStep1(data) {
  return { type: WIZARD_ACTION_TYPES.UPDATE_WIZARD_STEP1, payload: data };
}

export function updateWizardStep2(data) {
  return { type: WIZARD_ACTION_TYPES.UPDATE_WIZARD_STEP2, payload: data };
}

export function updateWizardStep3(data) {
  return { type: WIZARD_ACTION_TYPES.UPDATE_WIZARD_STEP3, payload: data };
}

export function setCurrentStep(step) {
  return { type: WIZARD_ACTION_TYPES.SET_CURRENT_STEP, payload: step };
}
