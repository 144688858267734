import config from '../../config/config';
import en from './en.json';
import fa from './fa.json';

const strings = () => {
  switch (config.currentLang.culture) {
    case 'en-US':
      return en;

    default:
      return fa;
  }
};

export default strings;
