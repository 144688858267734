import actions from './actions';
const { showDrawerAction, hideDrawerAction, resetDrawerAction, updateDrawerAction } = actions;

const updateDrawer = data => {
  return async dispatch => {
    try {
      return dispatch(updateDrawerAction(data));
    } catch (err) {
      console.log(err);
    }
  };
};

const showDrawer = data => {
  return async dispatch => {
    try {
      return dispatch(showDrawerAction(data));
    } catch (err) {
      console.log(err);
    }
  };
};

const hideDrawer = () => {
  return async dispatch => {
    try {
      dispatch(hideDrawerAction());
    } catch (err) {
      console.log(err);
    }
  };
};

const resetDrawer = () => {
  return async dispatch => {
    try {
      dispatch(resetDrawerAction());
    } catch (err) {
      console.log(err);
    }
  };
};

export { showDrawer, hideDrawer, resetDrawer, updateDrawer };
