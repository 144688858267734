export function replaceNode(tree, sourceNode, targetNodeID, incrementID, configs = { removeChildren: false }) {
  if (tree.node.id == targetNodeID) {
    sourceNode.node && (sourceNode.node.id = tree.node.id);

    const removedChidrenIDs = [];
    if (configs.removeChildren && tree.children) {
      (function setRemovedChildrenIDs(children) {
        for (const child of children) {
          removedChidrenIDs.push(child.node.id);

          if (child.children) setRemovedChildrenIDs(child.children);
        }
      })(tree.children);

      delete tree.children;
    }

    // set ID for new children
    if (sourceNode.children) {
      (function setIDs(children) {
        for (const child of children) {
          child.node.id || (child.node.id = (removedChidrenIDs.shift() ?? ++incrementID) + '');

          child.children && setIDs(child.children);
        }
      })(sourceNode.children);

      // set children if doesn't exist
      if (!tree.children) tree.children = [...sourceNode.children];
      else tree.children.push(...sourceNode.children);
    }

    sourceNode.node && (tree.node = sourceNode.node);
    sourceNode.edgeConfigs !== undefined && (tree.edgeConfigs = sourceNode.edgeConfigs);

    return sourceNode.children ? incrementID : true;
  }

  if (tree.children)
    for (const child of tree.children) {
      const replaceResult = replaceNode(child, sourceNode, targetNodeID, incrementID, configs);

      if (replaceResult) return replaceResult;
    }

  return false;
}
