import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import pages from '../../config/pages';
import AuthContainer from './comps/AuthContainer';
import ForgotPassword from './comps/ForgotPassword';
import ResetPassword from './comps/ResetPassword';
import SignIn from './comps/SignIn';
import SignUp from './comps/SignUp';

export default function Auth() {
  const match = useRouteMatch();

  return (
    <AuthContainer>
      <Switch>
        <Route path={match.path} exact component={SignIn} />
        <Route path={`/${pages.login}`} exact component={SignIn} />
        <Route path={`/${pages.signUp}`} component={SignUp} />
        <Route path={`/${pages.forgotPassword}`} component={ForgotPassword} />
        <Route path={`/${pages.resetPassword}`} component={ResetPassword} />
      </Switch>
    </AuthContainer>
  );
}
